export default {
    // [TODO] remove when not more used in application
    order: {
        create: {
            back: 'Terug'
        }
    },
    nav: {
        administration: {
            label: 'Administratie',
            tripsOverview: 'Trip',
            dossierEntry: 'Nieuw dossier',
            dossier: 'Dossiers',
            entity: 'Entiteiten',
            customerGroups: 'Klantgroepen',
            document: 'Documenten'
        },
    },
    customer: {
        overview: {
            showTrips: 'Toon trips',
            downloadButton: 'Download Twinfield export',
        },
        edit: {
            invoicingLocationTitle: 'Factuuradres',
            visitingLocationTitle: 'Bezoekadres',
            contactsTitle: 'Contacten (aantal)',
            paymentItem: 'Betaling',
        },
        field: {
            contacts: {
                label: 'Contacten'
            },
            contact: {
                value: {
                    dayshift: 'Dagdienst',
                    invoice: 'Factuur',
                    nightshift: 'Nachtdienst',
                    administration: 'Administratie',
                    general: 'Algemeen',
                    operation: 'Operatie'
                }

            },
            entity: {
                label: 'Entiteit'
            },
            customerGroup: {
                label: 'Klantgroep'
            },
            group: {
                label: 'Groep',
            },
            groupNumber: {
                label: 'Groepnummer',
            },
            groupName: {
                label: 'Groepsnaam',
            },
            selfBilling: {
                label: 'Self-billing',
                option: {
                    yes: 'Ja',
                    no: 'Niet'
                }
            },
            forceAutofinalizeActivities: {
                label: 'Kan automatisch afronden',
                option: {
                    yes: 'Ja',
                    no: 'Niet'
                }
            },
        },
        contact: {
            field: {
                addContact: {
                    label: 'Contact'
                },
                generalContact: {
                    label: 'Algemeen contact'
                },
                documentsInvoices: {
                    label: 'Documenten & Facturen'
                },
                customerUpdate: {
                    label: 'Klantupdate'
                },
                customerCustoms: {
                    label: 'Klant douane'
                },
                generalName: {
                    name: 'Naam afzender',
                    email: 'E-mailadres',

                },
                documentsInvoicesName: {
                    name: 'Naam afzender',
                    email: 'E-mailadres',
                },
                customerUpdateName: {
                    name: 'Naam afzender',
                    email: 'E-mailadres',
                },
                customerCustomName: {
                    name: 'Naam afzender',
                    email: 'E-mailadres',
                },
            },
            title: 'E-mailconfiguratie'
        },
        trip: {
            overview: {
                customerName: 'Klantnaam',
                customerReference: 'Referentie',
                loadingPlace: 'Laadplaats',
                loadingDate: 'Datum van laden',
                unloadingPlace: 'Losplaats',
                unloadingDate: 'Datum van lossen',
                statusIn: 'Status:',
            },
            statuses: {
                new: 'Nieuw',
                quoted: 'Begroot',
                accepted: 'Geaccepteerd',
                rejected: 'Afgewezen',
                planned: 'Gepland',
                completed: 'Voltooid',
                draft: 'Concept',
                sent: 'Verstuurd',
                in_progress: 'Bezig',
                invoiced: 'Gefactureerd',
                canceled: 'Geannuleerd',
                error: 'Fout'
            },
            create: {
                title: 'Klant aanmaken',
            },
            edit: {
                title: 'Klant bewerken',
            },
            delete: {
                title: 'Klant verwijderen',
            },
            field: {
                name: {
                    label: 'Naam',
                    placeholder: 'Vul een naam in ...',
                },
                id: {
                    label: 'Trip nr.',
                },
                customer: {
                    label: 'Klant',
                },
                salesPrice: {
                    label: 'Prijs',
                },
                reference: {
                    label: 'Referentie',
                },
                created_at: {
                    label: 'Gemaakt op',
                },
                updated_at: {
                    label: 'Bijgewerkt op',
                },
                status: {
                    label: 'Status',
                }
            },
        },
        locations: {
            title: 'Laad-/losadressen',
            button: {
                addAddress: 'Adres'
            },
            field: {
                name: {
                    label: 'Filiaal'
                },
                address: {
                    label: 'Adres'
                },
                street: {
                    label: 'Straat'
                },
                houseNumber: {
                    label: 'Nummer'
                },
                zipCode: {
                    label: 'Postcode'
                },
                country: {
                    label: 'Land'
                },
                city: {
                    label: 'Stad',
                },
                code: {
                    label: 'Code',
                }
            },
        },
        tab: {
            contact: 'Contacten',
            locations: 'Adresboek',
            routes: 'Trips & Prijzen'
        }
    },
    trip: {
        label: 'Trip',
        modal: {
            bulkForward: {
                header: 'Trip(s) uitbesteden',
                saveAndSend: 'Opslaan & Versturen'
            },
            completeTrip: {
                header: 'Trip voltooien',
                completeButton: 'Voltooi trip'
            },
            cancelTrip: {
                header: 'Trip annuleren',
                description: 'Weet je zeker dat je de trip wilt annuleren?',
                cancelButton: 'Trip annuleren',
            },
            reactivateTrip: {
                header: 'Trip heractiveren',
                description: 'Weet je zeker dat je de trip opnieuw wilt activeren?',
            },
            creditLimitReached: {
                header: 'Kredietlimiet bereikt',
                description: 'Houd er rekening mee dat de klant met dit verkoopprijsbedrag zijn kredietlimiet overschrijdt.',
                okButton: 'Bevestigen',
                createInvoiceButton: 'Factuur aanmaken',
                cancelTripButton: 'Trip annuleren',
            },
            subcontract: {
                header: 'Trip uitbesteed {{tripId}}',
                subcontractor: 'Onderaannemer',
                cargo: 'Vracht omschrijving',
                transportTrip: 'Transporttrip',
                pricing: 'Prijs',
                salesPrice: 'Verkoopprijs in euro',
                purchasePrice: 'Aankoopprijs in euro',
                marginBrutto: 'Bruto winstmarge',
                marginPercent: 'Procentuele marge',
                truckLicensePlate: 'Kenteken truck',
                trailerLicensePlate: 'Kenteken trailer',
                driverName: 'Naam bestuurder',
            },
            cancel: {
                header: 'Dossier annuleren',
                description: 'Weet je zeker dat je deze trip wilt annuleren?',
                confirmButton: 'Dossier annuleren',
                cancelButton: 'Sluit',
                reason: 'Reden (optioneel)',
                editDescription: 'Weet je zeker dat je deze trip wilt annuleren? Trip wordt ongepland en niet langer actief in het systeem. Als dit de enige trip in een dossier is, wordt het dossier ook geannuleerd.',
                success: 'Trip succesvol geannuleerd',
            },
            uncancel: {
                header: 'Heractiveren dossier',
                description: 'Weet je zeker dat je deze trip wilt heractiveren?',
                confirmButton: 'Dossier heractiveren',
                cancelButton: 'Sluit',
                editDescription: 'Weet je zeker dat je deze trip wilt heractiveren?',
                success: 'Trip succesvol geheractiveerd',
            }
        },
        buttons: {
            completeTrip: 'Trip voltooien',
            cancelTrip: 'Trip annuleren',
            reactivateTrip: 'Trip heractiveren',
            createInvoice: 'Factuur aanmaken',
            editInvoice: 'Factuur bewerken',
            subcontractTripNew: 'Nieuw uitbestede trip',
            subcontractTripEdit: 'Bewerk uitbestede trip',
            subcontract: 'Onderaanneming',
            collapseAll: 'Alles sluiten',
        },
        bulk: {
            trip: 'Trip',
            tableHeader: {
                price: 'Prijs',
                status: 'Status',
                type: 'Type',
                reference: 'Referentie',
                company: 'Filiaal',
                location: 'Locatie',
                dateFrom: 'Van',
                timeUntil: 'Tot',
                instructions: 'Instructies',
            },
            bulkAction: 'Bulkactie',
            forwardTripsTo: 'Trip uitbesteden aan',
            createShipment: 'Verzending aanmaken',
        },
        overview: {
            title: 'Trips',
            customerName: 'Klantnaam',
            customerReference: 'Klant referentie',
            invoiceReference: 'Referentie',
            invoiceStatus: 'Factuur status',
            loadingPlace: 'Laadplaats',
            loadingDate: 'datum van laden',
            unloadingPlace: 'Losplaats',
            unloadingDate: 'Datum van lossen',
            statusIn: 'Status:',
            status: 'Status',
            subcontracted: 'Uitbesteed',
            proofOfDelivery: 'POD',
            fleetNumbers: 'Trailer nr.',
            truckFleetNumbers: 'Truck nr.',
            truckLicensePlate: 'Kenteken truck',
            truckLicensePlateOwner: 'Truck kenteken eigenaar',
            truckLicensePlateSc: 'Truck kenteken sc',
            driver:{
                remarks: 'Opmerking chauffeur'
            },
            invoiced: {
                label: 'Gefactureerd',
                options: {
                    all: 'Alles',
                    yes: 'Ja',
                    no: 'Nee'
                }
            },
            hasProofOfDelivery: {
                label: 'Heeft POD',
                shortLabel: 'POD',
                options: {
                    all: 'Alles',
                    yes: 'Ja',
                    no: 'Nee',
                },
            },
        },
        create: {
            title: 'Trip aanmaken',
            bulkTitle: 'Trip',
            back: 'Terug',
        },
        createBulk: {
            title: 'Bulktrip',
            bulkTitle: 'Bulktrip ID',
            back: 'Terug',
        },
        edit: {
            title: 'Trip bewerken {{id}}',
            goodCardTitle: 'Goederen',
            activityCardTitleLoad: 'Verzender',
            subcontracted: 'Uitbesteed aan: ',
            activityCardTitleUnload: 'Geadresseerde',
            addAddress: 'Adres toevoegen',
            showJobs: 'Toon activiteiten',
            shipmentDetails: {
                activitiesGeneration: 'Activiteiten worden gegenereerd op basis van ingediende verzoeken.'
            },
            segments: {
                trip_details: 'Trip details',
                original_job: 'Originele activiteit',
                shipment_details: 'Verzendingsdetails',
                cargolines: 'Vrachtlijnen',
                customs: 'Douane',
                ferry_booking: 'Veerboot boeking',
                documents: 'Documenten',
                invoice: 'Factuur',
                trip_history: 'Trip geschiedenis',
                emptyBox: 'Lege doos',
                trips: 'Trip'
            }
        },
        delete: {
            title: 'Trip verwijderen',
        },
        field: {
            invoice: {
                label: 'Factuur',
            },
            tripNumber: {
                label: 'Trip no.',
            },
            id: {
                label: 'Trip no.',
            },
            status: {
                label: 'Status',
            },
            name: {
                label: 'Naam',
            },
            customer: {
                label: 'Klant',
                placeholder: 'Klant',
            },
            documents: {
                label: 'Bestanden toevoegen',
            },
            podDocument: {
                label: 'Proof of Delivery toevoegen',
            },
            invoiceReference: {
                label: 'Referentie',
                labelShort: 'Ref.',
                placeholder: 'Referentie',
            },
            subcontracted: {
                label: 'Uitbesteed',
                shortLabel: 'Sc',
                options: {
                    all: 'Alles',
                    own: 'Nee',
                    subcontracted: 'Ja',
                },
            },
            unit: {
                label: 'Eenheid',
                value: {
                    ftl: 'FTL',
                    ltl: 'LTL',
                    other: 'Anders',
                },
            },
            currency: {
                label: 'Munteenheid',
                value: {
                    euro: 'EUR',
                    pound: 'GBP',
                },
                code: {
                    euro: '€',
                    pound: '£',
                }
            },
            goodsDescription: {
                label: 'Goederen Omschrijving',
                placeholder: 'Algemene beschrijving van de goederen',
            },
            cargoDescription: {
                label: 'Vracht omschrijving',
                placeholder: 'Algemene beschrijving van de goederen',
            },
            reference: {
                label: 'Referentie',
            },
            weight: {
                label: 'Gewicht',
                placeholder: 'Totaal gewicht in kg',
            },
            packagingAmount: {
                label: 'Aantal',
            },
            packagingType: {
                label: 'Type',
            },
            adr: {
                label: 'ADR',
                placeholder: '123456',
                value: {
                    true: 'Ja',
                    false: 'Nee',
                },
            },
            remarks: {
                label: 'Trip opmerkingen',
                placeholder: 'Algemene opmerking over de trip',
                popup: 'Getoond op factuur, per trip',
            },
            salesPrice: {
                label: 'Prijs',
                placeholder: '€0,00,-',
            },
            assignedUser: {
                label: 'Toegewezen aan'
            },
            fuelSurcharge: {
                label: 'Brandstof',
                popup: {
                    true: 'Brandstof: bij de prijs inbegrepen',
                    false: 'Brandstof: NIET bij de prijs inbegrepen'
                }
            },
            customerUpdate: {
                label: 'Contact klantupdate',
            },
            customerCustoms: {
                label: 'Contact klantdouane',
            },
            createdAt: {
                label: 'Gemaakt om',
            },
            createdBy: {
                label: 'Gemaakt door',
            },
            BulkTripSummary: {
                label: 'Bulkroute samenvatting',
                amountLabel: 'Totaal',
                loadingDateLabel: 'Datum van laden',
                unloadingDateLabel: 'Datum van lossen'
            },
            destination: {
                label: 'Bestemming'
            },
            dossier: {
                label: 'Dossier',
                number: 'Dossier nr.'
            },
            trailer: {
                label: 'Trailer nr.',
                agLabel: 'A+G trailer',
                options: {
                    yes: 'Ja',
                    no: 'Nee'
                }
            },
            numberOfActivities: {
                label: '# Activiteiten',
            },
            datetimeFrom: {
                label: 'Van'
            },
            datetimeUntil: {
                label: 'Tot'
            },
            forwarded: {
                label: 'Geforward',
                options: {
                    yes: 'Ja',
                    no: 'Nee',
                }
            },
            charter: {
                label: 'Charter'
            },
            forwardingPurchasePrice: {
                label: 'Aankoop Prijs'
            },
            forwardingRemarks: {
                label: 'Forwarding opmerkingen',
                placeholder: 'Forwarding gerelateerde opmerkingen'
            },
            forwardingStatus: {
                label: 'Forwarding Status',
                values: {
                    to_sell: 'Te verkoop',
                    waiting_load: 'Wachtend lading',
                    waiting_pod: 'Wachtend pod',
                    finished: 'Voltooid'
                }
            },
            cancelReason: {
                label: 'Reden voor annulering',
            },
        },
        infoPopup: {
            invoiceReferenceTitle: 'Referentie',
            goodsDescriptionTitle: 'Goederen omschrijvingn',
            lastUnloadPlaceTitle: 'Laatste losplaats',
            loadingFromTitle: 'Van',
            loadingToTitle: 'Tot',
            finishedDateTitle: 'Afgerond om',
            plannedDepartureTitle: 'Gepland vertrek',
            actualDepartureTitle: 'Daadwerkelijk vertrek',
            plannedArrivalTitle: 'Geplande aankomst',
            actualArrivalTitle: 'Daadwerkelijke aankomst',
            activityFinishedAt: 'Activiteit geëindigd om',
            activityFinalizedAt: 'Activiteit afgerond om',
            openToFinalizeActivity: 'Open om activiteit af te ronden',
        },
        invoicing: {
            description: 'Transporten volgens bijlage',
            from: {
                label: 'van',
            },
            to: {
                label: 'tot',
            }
        },
        button: {
            cancel: 'Trip annuleren',
            uncancel: 'Trip heractiveren',
        },
    },
    geo: {
        notFound: 'Geen locatie gevonden!',
        InvalidURI: 'Ongeldige geocoding url',
    },
    activity: {
        overview: {
            proofOfDelivery: 'POD'
        },
        saveAndFinalizeButton: 'Opslaan & Afronden',
        skipValidation: 'Validatie overslaan',
        buttons: {
            finalize: 'Afronden',
            create: 'Activiteit',
            undoFinish: 'Voltooiing ongedaan maken'
        },
        changeStatusModal: {
            header: 'Activiteitsstatus wijzigen',
        },
        statuses: {
            draft: 'Concept',
            planned: 'Gepland',
            eta: 'Rijdend',
            arrived: 'Gearriveerd',
            waiting: 'Wachtend',
            started: 'Gestart',
            finished: 'Voltooid',
            finalized: 'Afgerond',
            executing: 'In uitvoering',
            canceled: 'Geannuleerd',
            new: 'Nieuw'
        },
        types: {
            abbreviations: {
                load: 'L',
                unload: 'U',
                driver_check_in: 'DCI',
                driver_change: 'DC',
                driver_check_out: 'DCO',
                truck_pick_up: 'TPU',
                truck_drop: 'TD',
                truck_solo: 'TS',
                trailer_pick_up: 'TPU',
                trailer_drop: 'TD',
                custom_activity: 'IA',
                customer_activity: 'KA',
                customer_group_allocation: 'KGT',
                undefined: '?'
            },
            actions: {
                load: 'Laden',
                unload: 'Lossen',
                customerActivity: 'Klant activiteit',
                truckPickUp: 'Truck pick up',
                truckDrop: 'Truck drop',
                truckSolo: 'Truck solo',
                trailerPickUp: 'Trailer pick up',
                trailerDrop: 'Trailer drop',
                driverChange: 'Driver wissel',
                driverCheckIn: 'Driver check in',
                driverCheckOut: 'Driver check uit',
                customActivity: 'Interne activiteit',
                customerGroupAllocation: 'Klantgroep wijzigen',
            },
            load: 'Laden',
            unload: 'Lossen',
            driver_check_in: 'Driver check in',
            driverCheckIn: 'Driver check in',
            driver_change: 'Driver wissel',
            driverChange: 'Driver wissel',
            driver_check_out: 'Driver check uit',
            driverCheckOut: 'Driver check uit',
            truck_pick_up: 'Truck pick up',
            truckPickUp: 'Truck pick up',
            truck_drop: 'Truck drop',
            truckDrop: 'Truck drop',
            truck_solo: 'Truck solo',
            truckSolo: 'Truck solo',
            trailer_pick_up: 'Trailer pick up',
            trailerPickUp: 'Trailer pick up',
            trailer_drop: 'Trailer drop',
            trailerDrop: 'Trailer drop',
            custom_activity: 'Interne activiteit',
            customActivity: 'Interne activiteit',
            customer_activity: 'Klant activiteit',
            customerActivity: 'Klant activiteit',
            customer_group_allocation: 'Klantgroep wijzigen',
            customerGroupAllocation: 'Klantgroep wijzigen',
            undefined: '- - - -'
        },
        field: {
            id: {
                label: 'ID'
            },
            bookingReference: {
                label: 'Boekingsreferentie'
            },
            unitLicensePlate: {
                label: 'Kenteken'
            },
            cargoSpecification: {
                label: 'Ladingspecificatie'
            },
            unumber: {
                label: 'Un-nummer'
            },
            proofOfDelivery: {
                label: 'POD'
            },
            class19: {
                label: 'Klasse (1-9)'
            },
            packgroup: {
                label: 'Packgroep (1-3)'
            },
            lq: {
                label: 'LQ'
            },
            mrnNumber: {
                label: 'Mrn Nummer'
            },
            additionalInformation: {
                label: 'Extra informatie'
            },
            goodsDescription: {
                label: 'Beschrijving'
            },
            remarks: {
                label: 'Opmerking'
            },
            status: {
                label: 'Status'
            },
            statusDraftAt: { label: 'Concept om', },
            statusPlannedAt: { label: 'Geplanned om', },
            statusEtaAt: { label: 'Vertrokken om', },
            statusArrivedAt: { label: 'Gearriveerd om', },
            statusWaitingAt: { label: 'Wachtende om', },
            statusStartedAt: { label: 'Gestart om', },
            statusFinishedAt: { label: 'Voltooid om', abbreviation: 'Volt.' },
            statusFinalizedAt: { label: 'Afgerond om', },
            statusCanceledAt: { label: 'Geannuleerd om', },
            type: {
                label: 'Type',
                value: {
                    load: 'Laden',
                    unload: 'Lossen',
                },
            },
            location: {
                label: 'Locatie',
                coordinates: 'Coordinaten',
                city: 'City',
                address: 'Address',
                placeholder: 'Kies een locatie',
            },
            orderedArrivalDatetimeFrom: {
                start: 'Start',
                label: 'Laden vanaf',
                labelByType: {
                    load: 'Laden vanaf',
                    unload: 'Lossen vanaf',
                }
            },
            orderedArrivalDatetimeUntil: {
                end: 'Eind',
                label: 'Laden tot',
                labelShort: 'Tot',
                labelByType: {
                    load: 'Laden tot',
                    unload: 'Lossen tot',
                }
            },
            instructions: {
                label: 'Instructies',
                placeholder: 'Chauffeur instructies',
                popup: 'Wordt gedeeld met de chauffeur bij het verzenden van de activiteit',
            },
            companyName: {
                label: 'Filiaal naam'
            },
            asap: {
                label: 'ASAP',
            },
            time: {
                label: 'Tijd',
            },
            from: {
                label: 'Van',
            },
            to: {
                label: 'Naar',
            },
            distance: {
                label: 'Afstand',
            },
            date: {
                label: 'Datum',
            },
        },
        modal: {
            title: {
                label: 'Activiteit afronden'
            },
            notification: {
                missingStatusFinalizedAt: 'Activiteit afronden onbekend datum'
            }
        },
        create: {
            title: 'Activiteit aanmaken'
        },
        customerLocation: {
            save: {
                on: 'Filiaal adres opslaan bij klant: AAN',
                off: 'Filiaal adres opslaan bij klant: UIT',
                addressBook: 'Opslaan in adresboek',
                nf: 'Locatie niet gevonden',
            },
            addressBookDuplicateError:{
                message: 'Adresboekitem met hetzelfde adres gevonden.'
            }
        },
        message: {
            dateSeparator: 'tot',
            asap: 'ASAP',
            trailer: {
                label: 'Trailer',
            },
            coordinates: {
                label: 'GPS',
            }
        },
        undoFinishConfirmation: {
            text: 'Weet je zeker dat je de voltooiing van deze activiteit ongedaan wilt maken? De activiteit zal terug gezet worden in de planning',
            header: 'Voltooiing ongedaan maken',
            notification: 'Activiteit succesvol ongedaan maken voltooid'
        }
    },
    dossier: {
        label: 'Dossier',
        overview: {
            title: 'Dossier [{{count}}]',
            truck: {
                label: 'Truck(s)'
            },
            trailer: {
                label: 'Trailer(s)'
            },
            button: {
                linkToSelfBill: {
                    label: 'Bundle & link self-bill [{{count}}]',
                    multipleCustomersSelectedTooltip: 'Cannot bundle dossiers from multiple customers into single selfbill.',
                    linkedDossierSelectedTooltip: 'In the selection there are dossiers, that are already linked to selfbill.',
                    nonSelfbilledCustomerSelectedTooltip: 'In the selection there are dossiers, that belong to non-billable customers.',
                },
                edit: 'Bewerk dossier',
                duplicate: 'Dupliceer dossier',
            },
            readyForInvoice: 'Ready for invoice',
        },
        edit: {
            title: 'Dossier: Nr.{{num}} W{{week}} {{cus}}',
            segments: {
                details: 'Dossier details',
                trips: 'Trips',
                documents: 'Documenten'
            },
            canceled:{
                infoBlockHeader: 'Waarschuwing: Dit dossier is geannuleerd',
                infoBlockDescription: 'Inclusief reizen worden niet weergegeven in de applicatie (plannen, uitvoeren, factureren, enz.)'
            },
        },
        saveConfirmation: {
            header: 'Dossier bevat uitvoerende/afgeronde activiteiten die zijn bewerkt',
            text: 'Weet je zeker dat je wilt sparen?',
            cantSaveLabel: 'U moet ten minste één activiteit toevoegen om het dossier op te slaan'
        },
        unsavedChangesConfirmation: 'Er zijn niet-opgeslagen wijzigingen. Weet je zeker dat je de pagina wilt verlaten?',
        create: {
            title: 'Dossier aanmaken',
            switchButton: {
                entryView: 'Nieuw dossier',
                editView: 'Bestaand dossier'
            }
        },
        field: {
            id: {
                label: 'Dossier ID'
            },
            dossierNumber: {
                label: 'Dossier nr.',
                shortLabel: 'Nr.'
            },
            customer: {
                label: 'Klant'
            },
            trips: {
                label: 'Trips'
            },
            weekNumber: {
                label: 'Week nr.',
                short: 'Week nr.',
                shortest: 'W',
            },
            remarks: {
                label: 'Interne dossier opmerkingen',
                placeholder: 'Algemene opmerking over het dossier',
                popup: 'Niet gedeeld met klant of chauffeur. Niet weergegeven op de factuur. Zichtbaar voor andere gebruikers binnen de TMS',
            },
            createdAt: {
                label: 'Gemaakt om',
            },
            createdBy: {
                label: 'Gemaakt door',
            },
            tripsCount: {
                label: '#R',
            },
            invoiceReference: {
                label: 'Referentie',
                shortLabel: 'Ref.',
                popup: 'Getoond op factuur, per dossier',
            },
            route:{
                label: 'Trip'
            },
            cancelReason: {
                label: 'Reden voor annulering',
            },
            selfBill: {
                reference: {
                    label: 'Self-bill ref',
                },
            },
            datetimeFrom: {
                label: 'Date',
            },
            price: {
                label: 'Price',
            },
            status: {
                option: {
                    yes: 'Ja',
                    no: 'Nee',
                    both: 'Beide',
                },
            },
            selfBilled: {
                label: 'Self-billed',
                options: {
                    yes: 'Ja',
                    no: 'Nee',
                    both: 'Beide',
                },
            },
            truck: {
                label: 'Truck',
            },
            trailer: {
                label: 'Trailer',
            },
            driver: {
                label: 'Driver',
            },
            invoice: {
                label: 'Factuur',
            }
        },
        deleteButton: {
            label: 'Dossier verwijderen'
        },
        button: {
            previewSavePdf: 'Preview Pdf',
            sendEmail: 'Verzend email',
            cancel: {
                label: 'Annuleer trips & dossier',
                alreadyStarted: 'Eén reis is al begonnen',
            },
            uncancel: {
                label: 'Heractiveren trips & dossier',
            },
        },
        modal: {
            cancel: {
                header: 'Dossier annuleren',
                confirmButton: 'Dossier annuleren',
                cancelButton: 'Sluit',
                reason: 'Reden (optioneel)',
                success: 'Dossier succesvol geannuleerd',
                description: 'Weet je zeker dat je dit dossier wilt annuleren?',
            },
            uncancel: {
                header: 'Heractiveren dossier',
                confirmButton: 'Dossier heractiveren',
                cancelButton: 'Sluit',
                success: 'Dossier succesvol geheractiveerd',
                description: 'Weet je zeker dat je dit wilt heractiveren dossier?',
            }
        },
    },
    entity: {
        overview: {
            title: 'Entiteiten',
            editButton: 'Entiteit bewerken',
            addButton: 'Entiteit'
        },
        edit: {
            title: 'Entiteit bewerken',
        },
        create: {
            title: 'Entiteit aanmaken',
        },
        field: {
            id: {
                label: 'ID'
            },
            name: {
                label: 'Entiteit naam',
            },
            vat: {
                label: 'BTW',
            },
            customer: {
                label: 'Klant',
                count: '# Klanten',
            },
            remarks: {
                label: 'Opmerking',
                placeholder: 'Algemene opmerking over de entiteit',
            },
        }
    },
    customerGroup: {
        overview: {
            title: 'Klantgroepen',
            addButton: 'Klantgroep'
        },
        edit: {
            title: 'Klantgroep bewerken',
        },
        create: {
            title: 'Klantgroep aanmaken',
        },
        field: {
            id: {
                label: 'ID'
            },
            name: {
                label: 'Groepsnaam',
            },
            groupNumber: {
                label: 'Groep nr.',
            },
            color: {
                label: 'Kleur',
            },
            customer: {
                label: 'Klant'
            },
            remarks: {
                label: 'Opmerking',
                placeholder: 'Algemene opmerking over de klantgroep',
            },
        }
    },
    document: {
        overview: {
            title: 'Documenten',
            addButton: 'Document',
            downloadButton: 'Download'
        },
        edit: {
            title: 'Document bewerken',
        },
        create: {
            title: 'Document aanmaken',
        },
        field: {
            id: {
                label: 'Document nr.'
            },
            type: {
                label: 'Bestandsformaat',
            },
            file: {
                label: 'Bestand',
            },
            path: {
                label: 'Path',
            },
        },
        filter: {
            noSubdirectories: 'Dossier bestanden',
            allFiles: 'Alle bestanden'
        },
        preview: {
            noFileToPreview: 'Geen preview beschikbaar',
            openFilePreview: 'Open bestandspreview',
            closeFilePreview: 'Bestandspreview sluiten',
        },
        input: {
            upload: {
                label: 'Bestand uploaden',
            },
            download: {
                label: 'Bestand downloaden'
            },
        }
    },
    invoiceLine: {
        field: {
            description: {
                linkedObjectTemplate: 'Dossier. A+G {{dossierNumber}}, Datum: {{date}}, Van: {{from}}, Tot: {{to}}, Afstand: {{distance}}'
            }
        }
    },
};
